import React, { useState } from 'react';
import './Landing.css';
import PopBlog from './PopBlog'; 

const Nav = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  return (
    <>
      <nav className="nav">
        <div className="logo-nav">
          <a href="/">
            <img src="/logocompletoazul.png" alt="Logo" />
          </a>
        </div>

        <div className="menu-nav">
          <a href="#solution">Solución</a>
          <a href="#" onClick={openModal}>Blog</a>
          <a href="#contact">Contactanos</a>
        </div>

        <div className="buttons-nav">
          <button className="login-button-nav"
          onClick={() => window.location.href = 'app.softpital.com'}>
          Iniciar Sesión</button>
          <button
            className="demo-button-nav"
            onClick={() => window.location.href = 'https://calendly.com/contacto-softpital/junta-softpital'}>
            AGENDA UNA DEMO
          </button>
        </div>
      </nav>

      <PopBlog show={modalVisible} handleClose={closeModal} /> 
    </>
  );
};

export default Nav;


