import React from 'react';
import './Landing.css';

function card() {
    return (
      <section id="solution">
      <div className="card-container">
        <div className="card">
          <h2>Softpital y empresas proveedoras de equipos médicos</h2>
          <a href="https://www.canva.com/design/DAGP4OygMxc/G587nk3WuhwnVA0EOskG8Q/view">CONOCE MÁS BENEFICIOS →</a>
        </div>
        <div className="card">
          <h2>Softpital e instituciones de salud (hospitales, clínicas, consultorios)</h2>
          <a href="https://www.canva.com/design/DAGQrwo-5AI/efUiQlU-eipJ8Til50VmAA/view?utm_content=DAGQrwo-5AI&utm_campaign=designshare&utm_medium=link&utm_source=editor">CONOCE MÁS BENEFICIOS →</a>
        </div>
      </div>
      </section>
    );
  }
  
export default card;